import React, { useState } from 'react'
import Header from './section/Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import Chart2 from './section/Chart2'
import Chart3 from './section/Chart3'

const Account = () => {

    const [profileImage, setProfileImage] = useState('./images/profile.jpg');

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfileImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
        
    };
    // localStorage.setItem('profileimg', JSON.stringify(profileImage));

    return (
        <div>
            <Header />

            <div className="account py-5">
                <div className="container">
                    <h2 className='pb-lg-5 p-3 text-center text-lg-start'>Account</h2>
                    <div className="row">
                        <div className="col-lg-9 accountshow">
                            <div className="img" style={{ position: 'relative' }}>
                                <img src={profileImage} alt="" />

                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        opacity: 0,
                                        cursor: 'pointer',
                                        zIndex: '10'
                                    }}
                                />
                                <div className="dark-overlay">Change Photo</div>
                            </div>
                            <div className="contetn ms-lg-5">
                                <h4>Quality IN Hotel</h4>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora molestias eum aspernatur perferendis fugit sed dolores officiis quod aperiam, dolorem, sunt rem iste doloremque, esse voluptas obcaecati ducimus placeat ullam.</p>
                                <div className="numemailcol">
                                    <div className="m-number">
                                        <FontAwesomeIcon icon={faPhone} />
                                        <span className='ms-3'>+91 123 4567 890</span>
                                    </div>
                                    <div className="mail ms-lg-5">
                                        <FontAwesomeIcon icon={faEnvelope} />
                                        <span className='ms-3'>teshotel@test.com</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="chart">
                    <div className="container py-5">
                        <div className="row">
                            <div className="col-lg-8 pt-lg-5">
                                <Chart3 />
                            </div>
                            <div className="col-lg-4 p-5">
                                <Chart2 />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Account
