// src/features/counter/counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const ragisterSlice = createSlice({
    name: 'ragister',
    initialState: {
        registrationData: [],
    },
    reducers: {
        setRegistrationData: (state, action) => {
            state.registrationData.push(action.payload);
        },
        
    },
});

export const { setRegistrationData, findUserByEmail } = ragisterSlice.actions;
export default ragisterSlice.reducer;
