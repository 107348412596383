import React, { useState } from 'react'
import Header from './section/Header'
import { Input, Space } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import { Navigation } from 'swiper/modules';

const About = () => {

  const { Search } = Input;
  const [searchTerm, setSearchTerm] = useState('');

  const onSearch = (value, _e, info) => {
    setSearchTerm(value);
  };

  const highlightText = (text) => {

    if (searchTerm.trim() === '') {
      return text;
    }

    const regex = new RegExp(`(${searchTerm})`, 'gi');

    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{ backgroundColor: 'yellow' }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const teamMembers = [
    {
      id: 1,
      name: highlightText('Jenny Wilson'),
      role: 'Co-founder / CEO',
      image: './images/img5.png',
    },
    {
      id: 2,
      name: highlightText('John Doe'),
      role: 'CTO',
      image: './images/img6.png',
    },
    {
      id: 3,
      name: 'Jane Smith',
      role: highlightText('Marketing Director'),
      image: './images/img7.png',
    },
  ];


  return (
    <div>
      <Header />
      <div className="about">


        <div className="container py-4">
          <div className="row section1">
            <div className="col-lg-6">
              <h3>About Us</h3>
            </div>
            <div className="col-lg-6 searchbar">
              <Space direction="vertical">
                <Search placeholder="search text" allowClear onSearch={onSearch} className='searchbarComponent' />
              </Space>
            </div>
          </div>
        </div>


        <div className="slider">
          <div className="container">
            <div className="row">
              <div className="col">

                <Swiper
                  modules={[Navigation]}
                  spaceBetween={20}
                  slidesPerView={1}
                  navigation
                  
                >
                  <SwiperSlide>
                    <img width='100%' src="./images/img3.png" alt="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img width='100%' src="./images/img3.png" alt="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img width='100%' src="./images/img3.png" alt="" />
                  </SwiperSlide>
                </Swiper>

              </div>
            </div>
          </div>
        </div>


        <div className="section3">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="content">
                  <h4>{highlightText("Lorem ipsum dolor sit amet consectetur adipisicing elit Lorem, ipsum.")}</h4>
                  <p>{highlightText("Lorem orem ipsum dolor sit amet consectetur adipisicing elit. Possimus porro accusantium voluptates laudantium voluptatum corrupti rerum quam eveniet natus consequuntur. ipsum dolor sit amet consectetur adipisicing elit. Libero ipsa asperiores nihil molestias pariatur hic ad quibusdam nobis praesentium excepturi.")}</p>
                  <p>{highlightText("Lorem Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque, similique ut explicabo unde labore soluta, vero eum harum impedit id voluptatum placeat ratione facilis non dolor magni tempora? Soluta, magnam. Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus porro accusantium voluptates laudantium voluptatum corrupti rerum quam eveniet natus consequuntur. ipsum dolor sit amet consectetur adipisicing elit. Libero ipsa asperiores nihil molestias pariatur hic ad quibusdam nobis praesentium excepturi.")}</p>
                  <button className='btn btn-primary'>Get Started</button>

                </div>
              </div>
              <div className="col-lg-6">
                <div className="img">
                  <img src="./images/img4.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="team pb-5">
          <div className="container">
            <div className="row">
              {teamMembers.map((member) => (
                <div key={member.id} className="col-lg-4 p-lg-5 p-3">
                  <div className="item">
                    <div className="img">
                      <img src={member.image} alt={member.name} />
                    </div>
                    <h5>{member.name}</h5>
                    <p>{member.role}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>


      </div>
    </div>
  )
}

export default About
