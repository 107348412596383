import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { login } from '../redux/authReducer';

function Login() {

    const dispatch = useDispatch();

    const navigate = useNavigate();
    
    const [data, setData] = useState({
        email: '',
        password: ''
    });

    const [error, setError] = useState({
        email: '',
        password: ''
    });

    const validForm = () => {

        let isValid = true;
        const newError = { ...error };

        if (data.email.trim() === '') {
            newError.email = 'required'
            isValid = false
        } else {
            newError.email = ''
        }

        if (data.password.trim() === '') {
            newError.password = 'Required'
            isValid = false
        } else if (data.password.length < 8) {
            newError.password = 'Minimum 8 Charactor'
            isValid = false
        } else {
            newError.password = ''
        }

        setError(newError)
        return isValid;
    }

    const loginSuccess = (e) => {
        e.preventDefault();

        const isValid = validForm();

        if (isValid) {

            // LocalStorage
            const userData = JSON.parse(localStorage.getItem('user'));

            if (userData && userData.email === data.email && userData.password === data.password) {

                console.log('Login:', data);

                
                navigate('/')

                dispatch(login())

            } else {

                alert('Invalid credentials or user not registered');

            }

        } else {

            alert('Enter Your Details')

        }
    }

    return (
        <div>

            <div className="login-form">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 form-section">
                            <div className="shap-img-one">
                                <img src="images/shap-01.png" alt="Shape 01" />
                            </div>
                            <div className="shap-img-two">
                                <img src="images/shap-02.png" alt="Shape 02" />
                            </div>
                            <div className="form-inner">
                                <h2>
                                    Sign in<br />Access your Account.
                                </h2>
                                <form onSubmit={loginSuccess} method="POST">
                                    <div className="form-group form-box">
                                        <label>Email Address</label>
                                        <input
                                            type="email"
                                            name="email"
                                            className="form-control"
                                            placeholder="Email"
                                            aria-label="Email Address"
                                            value={data.email}
                                            onChange={(e) => setData({ ...data, email: e.target.value })}
                                        />
                                        <p className='errormsg'>{error.email}</p>
                                    </div>
                                    <div className="form-group form-box">
                                        <label>Password</label>
                                        <input
                                            type="password"
                                            name="password"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Password"
                                            aria-label="Password"
                                            value={data.password}
                                            onChange={(e) => setData({ ...data, password: e.target.value })}
                                        />
                                        <p className='errormsg'>{error.password}</p>
                                    </div>
                                    <div className="checkbox form-group clearfix">
                                        <Link to="/ForgotPassword" className="link-dark float-end forgot-password">
                                            Forgot password?
                                        </Link>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary btn-lg login-btn">
                                            Sign In
                                        </button>
                                    </div>
                                </form>
                                <p>
                                    Don't have an account? <Link to="/Ragister" className="thembo">Create your account now.</Link>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 right-img" style={{ backgroundImage: 'url(images/bg-img-01.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
