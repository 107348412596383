import { faBell } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Dropdown, Space } from 'antd'
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/authReducer';


function Header() {

    const dispatch = useDispatch();


    const location = useLocation();


    const handleLogout = () => {
        dispatch(logout())
    };

    const items = [
        {
            label: (
                <Link to="/Account" className={`nav-item ${location.pathname === '/Account' ? 'active' : ''}`}>
                    <span className="nav-link" aria-current="page">
                        Account
                    </span>
                </Link>
            ),
            key: '0',
        },
        {
            label: (
                <Link to="/ChangePassword" className={`nav-item ${location.pathname === '/ChangePassword' ? 'active' : ''}`}>
                    <span className="nav-link" aria-current="page">
                        Change Password
                    </span>
                </Link>
            ),
            key: '1',
        },
        {
            type: 'divider',
        },
        {
            label: <button className="btn btn-primary" onClick={handleLogout} >Logout</button>,
            key: '3',
        },
    ];

    return (
        <div>
            <div className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <nav className="navbar navbar-expand-lg">
                                <div className="logo">
                                    <Link className="navbar-brand" to="/">
                                        <img src="images/logo.png" alt="Logo" />
                                    </Link>
                                </div>
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                        <li className={`nav-item ${location.pathname === '/' ? 'active' : ''}`}>
                                            <span className="nav-link " aria-current="page">
                                                <Link to="/">Home</Link>
                                            </span>
                                        </li>
                                        <li className={`nav-item ${location.pathname === '/Reviews' ? 'active' : ''}`}>
                                            <span className="nav-link" aria-current="page">
                                                <Link to="/Reviews">Reviews</Link>
                                            </span>
                                        </li>
                                        <li className={`nav-item ${location.pathname === '/Analytics' ? 'active' : ''}`}>
                                            <span className="nav-link" aria-current="page">
                                                <Link to="/Analytics">Analytics</Link>
                                            </span>
                                        </li>
                                        <li className={`nav-item ${location.pathname === '/About' ? 'active' : ''}`}>
                                            <span className="nav-link" aria-current="page">
                                                <Link to="/About">About Us</Link>
                                            </span>
                                        </li>
                                        <li className={`nav-item ${location.pathname === '/Help' ? 'active' : ''}`}>
                                            <span className="nav-link" aria-current="page">
                                                <Link to="/Help">Help</Link>
                                            </span>
                                        </li>
                                        <li className={`nav-item ${location.pathname === '/Contact' ? 'active' : ''}`}>
                                            <span className="nav-link" aria-current="page">
                                                <Link to="/Contact">Contact Us</Link>
                                            </span>
                                        </li>
                                    </ul>
                                    <ul className="nav-right">
                                        <li className={`nav-item dropdown ${location.pathname === '/Notification' ? 'active' : ''}`}>
                                            <Link to='/Notification' className="nav-link">
                                                <FontAwesomeIcon icon={faBell} />
                                                <span className="notify">
                                                    <span className="blink"></span>
                                                    <span className="dot"></span>
                                                </span>
                                            </Link>
                                        </li>
                                        <li className="nav-item dropdown user-profile">
                                            <Dropdown menu={{ items }} trigger={['click']}>
                                                <span className="nav-link" onClick={(e) => e.preventDefault()}>
                                                    <Space>
                                                        <img src="images/profile-img.png" alt="Avatar" />
                                                        <span className="bg-success user-status"></span>
                                                    </Space>
                                                </span>
                                            </Dropdown>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
