import React, { useState } from 'react'
import Header from './section/Header'
import { Rate } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

const ReviewData = [
  {
    img: './images/profile-img.png',
    name: 'Smruti Parmar',
    review: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam, possimus?Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam, possimus?',
    rating: 4,
  },
  {
    img: './images/profile-img.png',
    name: 'Vijay Singh',
    review: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam, possimus?Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam, possimus?',
    rating: 3.5
  },
  {
    img: './images/profile-img.png',
    name: 'Kanjal sharma',
    review: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam, possimus?Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam, possimus?',
    rating: 2
  },
  {
    img: './images/profile-img.png',
    name: 'Arun Sing',
    review: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam, possimus?Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam, possimus?',
    rating: 5
  },
  {
    img: './images/profile-img.png',
    name: 'Smruti Parmar',
    review: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam, possimus?Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam, possimus?',
    rating: 4
  },
  {
    img: './images/profile-img.png',
    name: 'Varun Gohel',
    review: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam, possimus?Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam, possimus?',
    rating: 5
  },
  {
    img: './images/profile-img.png',
    name: 'Chetan kanta',
    review: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam, possimus?Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam, possimus?',
    rating: 1
  }

]

function Reviews() {

  const [selectedReview, setSelectedReview] = useState(0);
  const [likes, setLikes] = useState(Array(ReviewData.length).fill(0));
  const [replyText, setReplyText] = useState('');
  const [replies, setReplies] = useState(Array(ReviewData.length).fill([]));


  const onLike = (index) => {
    const updatedLikes = [...likes];
    updatedLikes[index] = 1 - updatedLikes[index];
    setLikes(updatedLikes);
  };

  const handleReviewClick = (index) => {
    setSelectedReview(index);
  };

  const handleReplySubmit = () => {
    // Implement your reply logic here
    const updatedReplies = [...replies];
    updatedReplies[selectedReview] = [
      ...updatedReplies[selectedReview],
      { rname: 'Your Name', reply: replyText },
    ];
    setReplies(updatedReplies);
    setReplyText('');
  };

  return (
    <div>
      <Header />
      <div className="review">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 py-3">
              <h3>Review</h3>
              {ReviewData.map((review, index) => (
                <div
                  key={index}
                  className="review-content"
                  onClick={() => handleReviewClick(index)}
                >
                  <div className='profile'>
                    <img src={review.img} alt="" />
                    <h4>{review.name}</h4>
                  </div>
                  <p>{review.review.split(' ').slice(0, 10).join(' ')}...</p>
                  <div className="rating">
                    <Rate disabled allowHalf value={review.rating} />
                    <div>
                      <div className="like">
                        <FontAwesomeIcon onClick={() => { onLike(index) }} icon={faHeart} style={{ color: likes[index] ? 'red' : 'gray' }} />
                        <p className='m-0 ps-4'>{replies[index].length} reply</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="col-lg-6 review-details py-3">
              <h3 className='text-center'>Review Details</h3>
              {selectedReview !== null && (
                <div className="review-detail-content">
                  <div className='profile'>
                    <img src={ReviewData[selectedReview].img} alt="" />
                    <h2 className='py-2'>{ReviewData[selectedReview].name}</h2>
                  </div>
                  <p>{ReviewData[selectedReview].review}</p>
                  <div>
                    <Rate disabled allowHalf value={ReviewData[selectedReview].rating} />
                  </div>

                  {replies[selectedReview] && replies[selectedReview].length > 0 && (
                    <div className="replies">
                      <h4>Replies:</h4>
                      {replies[selectedReview].map((reply, replyIndex) => (
                        <div key={replyIndex}>
                          <p><b>{reply.rname}</b> {reply.reply}</p>
                        </div>
                      ))}
                    </div>
                  )}

                  <div className="review-reply py-4">
                    <div className="form">
                      <div className="form-group">
                        <textarea
                          name="reply"
                          className='form-control'
                          id="reply"
                          cols="30"
                          rows="6"
                          placeholder='Reply'
                          value={replyText}
                          onChange={(e) => setReplyText(e.target.value)}
                        ></textarea>
                        <button type='submit' className='btn btn-primary mt-3' onClick={handleReplySubmit}>Send</button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reviews
