import React, { useState } from 'react';
import Header from './section/Header';
import { Input, Space } from 'antd';
import debounce from 'lodash/debounce';

const { Search } = Input;

const notificationsData = [
  {
    id: 1,
    category: 'Today',
    notifications: [
      {
        id: 1,
        image: './images/profile-img.png',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting',
        timestamp: 'Today, 09:20 AM',
      },
      {
        id: 2,
        image: './images/profile-img.png',
        content: 'industry. Lorem Ipsum has been the industrys standard dummy',
        timestamp: 'Today, 09:20 AM',
      },
      {
        id: 3,
        image: './images/profile-img.png',
        content: 'text ever since the 1500s, when an unknown printer took a galley of type and',
        timestamp: 'Today, 09:20 AM',
      },
    ],
  },
  {
    id: 2,
    category: 'Yesterday',
    notifications: [
      {
        id: 1,
        image: './images/profile-img.png',
        content: 'scrambled it to make a type specimen book. It has survived not only',
        timestamp: 'Yesterday, 09:20 AM',
      },
      {
        id: 2,
        image: './images/profile-img.png',
        content: 'five centuries, but also the leap into electronic typesetting, remaining essentially unchanged',
        timestamp: 'Yesterday, 09:20 AM',
      },
      {
        id: 3,
        image: './images/profile-img.png',
        content: 'It was popularised in the 1960s with the release of Letraset sheets',
        timestamp: 'Yesterday, 09:20 AM',
      },

    ],
  },
  {
    id: 3,
    category: 'This Week',
    notifications: [
      {
        id: 1,
        image: './images/profile-img.png',
        content: 'containing Lorem Ipsum passages, and more recently with desktop publishing',
        timestamp: '15/01/2023, 09:20 AM',
      },
      {
        id: 2,
        image: './images/profile-img.png',
        content: ' is a long established fact that a reader will be distracted by the readable content',
        timestamp: '15/01/2023, 09:20 AM',
      },
      {
        id: 3,
        image: './images/profile-img.png',
        content: 'of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more',
        timestamp: '15/01/2023, 09:20 AM',
      },
      {
        id: 4,
        image: './images/profile-img.png',
        content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita, vero!',
        timestamp: '15/01/2023, 09:20 AM',
      },

    ],
  },
];

const Notification = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const delayedSearch = debounce((value) => {
    setSearchTerm(value);
  }, 300);

  const onSearchChange = (e) => {
    delayedSearch(e.target.value);
  };

  const filteredNotificationsData = notificationsData.map((category) => ({
    ...category,
    notifications: category.notifications.filter((notification) =>
      notification.content.toLowerCase().includes(searchTerm.toLowerCase())
    ),
  }));


  return (
    <div>
      <Header />
      <div className="notification">
        <div className="container py-4">
          <div className="row section1">
            <div className="col-lg-6">
              <h3>Notification</h3>
            </div>
            <div className="col-lg-6 searchbar">
              <Space direction="vertical">
                <Search placeholder="search text" allowClear onChange={onSearchChange} className='searchbar_Notification' />
              </Space>
            </div>
          </div>
        </div>

        <div className="notify">
          <div className="container">
            <div className="row">
              {filteredNotificationsData.map((category) => (
                <div key={category.id} className="col-lg-6 ">
                  <h5>{category.category}</h5>
                  <div className="notify_list">
                    {category.notifications.map((notification) => (
                      <div key={notification.id} className="notify_list_Item">
                        <div className="img">
                          <img src={notification.image} alt="" />
                        </div>
                        <div className="content">
                          <h6>{notification.content}</h6>
                          <span>{notification.timestamp}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
