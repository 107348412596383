import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function ForgotPassword() {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const [countdown, setCountdown] = useState(20);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        let timer;
        if (isOtpSent && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);
        }

        return () => {
            clearInterval(timer);
        };
    }, [isOtpSent, countdown]);


    const validateEmail = (value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
    };

    const validateOtp = (value) => {
        return value.length === 6;
    };

    const validatePassword = (value) => {
        return value.length >= 8;
    };

    const validateConfirmPassword = (value) => {
        return value === newPassword;
    };

    // LocalStorage
    const generateOtp = () => {
        return Math.floor(100000 + Math.random() * 900000);
    };

    // LocalStorage
    const getRegisteredUsers = () => {
        const registeredUsersString = localStorage.getItem('user');
        return registeredUsersString ? JSON.parse(registeredUsersString) : {};
    };

    const handleSendOtp = () => {
        if (validateEmail(email)) {

            // LocalStorage
            const registeredUsers = getRegisteredUsers();

            if (registeredUsers.email === email) {
                // LocalStorage
                const generatedOtp = generateOtp();
                localStorage.setItem('otp', generatedOtp);
                alert(generatedOtp);

                setIsOtpSent(true);
                setErrors({});
            } else {
                setErrors({ email: 'Email not registered' });
            }

        } else {
            setErrors({ email: 'Invalid email address' });
        };
        setCountdown(20);
    };

    const handleVerifyOtp = () => {
        // LocalStorage
        const storedOtp = localStorage.getItem('otp');

        if (validateOtp(otp) && otp === storedOtp) {
            setIsOtpVerified(true);
            setErrors({});
        } else {
            setErrors({ otp: 'Invalid OTP' });
        }
    };

    const handleChangePassword = () => {
        const passwordValid = validatePassword(newPassword);
        const confirmPasswordValid = validateConfirmPassword(confirmPassword);

        if (passwordValid && confirmPasswordValid) {

            // LocalStorage
            const registeredUsers = getRegisteredUsers();

            // LocalStorage
            if (registeredUsers.email === email) {
                
                registeredUsers.password = newPassword;
                localStorage.setItem('user', JSON.stringify(registeredUsers));

                alert('Password changed successfully');
                navigate('/Login');

                // LocalStorage
                localStorage.removeItem('otp');

            } else {
                setErrors({
                    newPassword: 'Password change failed',
                    confirmPassword: 'Password change failed',
                });
            }

        } else {
            setErrors({
                newPassword: passwordValid ? '' : 'Password must be at least 8 characters',
                confirmPassword: confirmPasswordValid ? '' : 'Passwords do not match',
            });
        }
    };

    return (
        <div>
            <div className="login-form">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 form-section">
                            <div className="shap-img-one">
                                <img src="images/shap-01.png" alt="Shape 01" />
                            </div>
                            <div className="shap-img-two">
                                <img src="images/shap-02.png" alt="Shape 02" />
                            </div>
                            <div className="form-inner">


                                {!isOtpVerified && (
                                    <div>
                                        <h2>
                                            Forgot Password
                                        </h2>
                                        <div className="form-group form-box">
                                            <label>Email:</label>
                                            <input
                                                type="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                className={`${errors.email ? 'error' : ''} form-control`}
                                            />
                                            {errors.email && <div className="error-message errormsg">{errors.email}</div>}
                                        </div>
                                        <div className="form-group">
                                            <button className='btn btn-primary' onClick={handleSendOtp} disabled={isOtpSent && countdown !== 0}>
                                                {countdown === 0 ? "Resend OTP" :
                                                    isOtpSent ? `Resend OTP in ${countdown}s` : 'Send OTP'}
                                            </button>
                                        </div>

                                        {isOtpSent && (
                                            <div>
                                                <div className="form-group form-box">
                                                    <label>Enter OTP:</label>
                                                    <input
                                                        type="text"
                                                        value={otp}
                                                        onChange={(e) => setOtp(e.target.value)}
                                                        className={`${errors.otp ? 'error' : ''} form-control`}
                                                    />
                                                    {errors.otp && <div className="error-message errormsg">{errors.otp}</div>}
                                                </div>
                                                <div className="form-group">
                                                    <button className='btn btn-primary' onClick={handleVerifyOtp}>Continue</button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}

                                {isOtpVerified && (
                                    <div>
                                        <h2>Change Password</h2>

                                        <div className="form-group form-box">
                                            <label>New Password:</label>
                                            <input
                                                type="password"
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                className={`${errors.newPassword ? 'error' : ''} form-control`}
                                            />
                                            {errors.newPassword && <div className="error-message errormsg">{errors.newPassword}</div>}
                                        </div>

                                        <div className="form-group form-box">
                                            <label>Confirm Password:</label>
                                            <input
                                                type="password"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                className={`${errors.confirmPassword ? 'error' : ''} form-control`}
                                            />
                                            {errors.confirmPassword && (
                                                <div className="error-message errormsg">{errors.confirmPassword}</div>
                                            )}
                                        </div>

                                        <div className="form-group">
                                            <button className='btn btn-primary' onClick={handleChangePassword}>Change Password</button>
                                        </div>
                                    </div>
                                )}


                            </div>
                        </div>
                        <div className="col-lg-6 right-img" style={{ backgroundImage: 'url(images/bg-img-01.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;
