import React, { useState } from 'react'
import Header from './section/Header'

function Contact() {


  const [data, setData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [error, setError] = useState({
    name: '',
    email: '',
    message: ''
  });

  const validForm = () => {

    let isValid = true;
    const newError = { ...error };

    if (data.name.trim() === '') {
      newError.name = 'required'
      isValid = false
    } else {
      newError.name = ''
    }

    if (data.email.trim() === '') {
      newError.email = 'required'
      isValid = false
    } else {
      newError.email = ''
    }


    if (data.message.trim() === '') {
      newError.message = 'required'
      isValid = false
    } else {
      newError.message = ''
    }

    setError(newError)
    return isValid;
  }

  const loginSuccess = (e) => {
    e.preventDefault();

    const isValid = validForm();

    if (isValid) {

      console.log('Contact:', data);

    } else {

      alert('Enter Your Details')

    }
  }

  const bannerStyle = {
    maxHeight: '300px',
  };

  return (
    <div>
      <Header />

      <div className="contact_us">
        <div className="banner" style={bannerStyle} >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8">
                <div className="banner-content">
                  <h1>Contact Us</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contact">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 form-section">
                <div className="form-inner">
                  <h3 className='fs-2'>Contact Us</h3>
                  <p>Please till this form in a decent manner</p>

                  <form onSubmit={loginSuccess} method="POST">

                    <div className="form-group form-box">
                      <label>Full Name</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        aria-label="name"
                        value={data.name}
                        onChange={(e) => setData({ ...data, name: e.target.value })}
                      />
                      <p className='errormsg'>{error.name}</p>
                    </div>

                    <div className="form-group form-box">
                      <label>Email Address</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        aria-label="Email Address"
                        value={data.email}
                        onChange={(e) => setData({ ...data, email: e.target.value })}
                      />
                      <p className='errormsg'></p>
                    </div>

                    <div className="form-group form-box">
                      <label>Message</label>
                      <textarea
                        name="message"
                        id="message"
                        className="form-control"
                        cols="30"
                        rows="10"
                        value={data.message}
                        onChange={(e) => setData({ ...data, message: e.target.value })}
                      ></textarea>
                      <p className='errormsg'></p>
                    </div>


                    <div className="form-group">
                      <button type="submit" className="btn btn-primary btn-lg login-btn"> Send</button>
                    </div>

                  </form>

                </div>

              </div>
              <div className="col-lg-6 right-side">
                <div className="img mt-5">
                  <img src="./images/img-02.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
