import React from 'react'
import Header from './section/Header'
import { Link } from 'react-router-dom'

function home() {
  return (
    <div>
      <Header />

      {/* Banner */}
      <div className="banner">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="banner-content">
                <h1>Stay informed with the best reviews in town.</h1>
                <p>
                  They should be eye-catching and evoke positive emotions, so they are recalled quickly and easily by the reader.
                </p>
                <Link className="btn btn-primary" to="/">
                  Review Submit
                </Link>

              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center mb-4 mb-lg-0">
              <h2 className="section-title mb-3">Reviews that make every hotel stay the best it can be.</h2>
              <p>
                Some additional slogan ideas related to hotel reviews might include "Stay in Style at Our Boutique Hotel," "Unwind in Serenity and Luxury," "Experience True Hospitality at Our Hotel," "Our Hotel: Where Comfort Meets Convenience," "Elevate Your Stay with Upscale Amenities," and "Experience the Best of the City at Our Downtown Hotel." Whether you're promoting a luxury resort, a budget-friendly motel, or a trendy boutique hotel, there's a slogan that can help you capture the attention of potential guests and show off your unique offerings.
              </p>
              <p className="mb-0">Hotel review blog slogans are short and catchy phrases that convey the essence of a hotel to potential guests. They are critical to the success of any hotel review blog since they provide a memorable and concise summary of the hotel's outstanding features.</p>
            </div>
            <div className="col-lg-6">
              <img className="w-100" src="images/img-01.jpg" alt="Hotel" />
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default home
