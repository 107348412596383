import React, { useState } from 'react'
import Header from './section/Header'
import { Input, Space } from 'antd';


const sections = [
  {
    title: 'The standard Lorem Ipsum passage, used since the 1500s ?',
    content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero tempore voluptatum harum ipsam temporibus non praesentium quo architecto, impedit velit. Aliquam tenetur incidunt maiores natus vitae. Laudantium officia ut dolorum nisi. Ullam incidunt a, at iste dolore perspiciatis dignissimos error quo nostrum quod illum, impedit enim odit illo pariatur excepturi. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laudantium, soluta vero, perspiciatis dolor, voluptates doloremque hic ipsum tenetur tempore maiores ipsa aliquam maxime veniam? Itaque nemo harum maxime. Sit, deserunt.',
  },
  {
    title: 'Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC ?',
    content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero tempore voluptatum harum ipsam temporibus non praesentium quo architecto, impedit velit. Aliquam tenetur incidunt maiores natus vitae. Laudantium officia ut dolorum nisi. Ullam incidunt a, at iste dolore perspiciatis dignissimos error quo nostrum quod illum, impedit enim odit illo pariatur excepturi. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laudantium, soluta vero, perspiciatis dolor, voluptates doloremque hic ipsum tenetur tempore maiores ipsa aliquam maxime veniam? Itaque nemo harum maxime. Sit, deserunt.',
  },
  {
    title: '1914 translation by H. Rackham ?',
    content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero tempore voluptatum harum ipsam temporibus non praesentium quo architecto, impedit velit. Aliquam tenetur incidunt maiores natus vitae. Laudantium officia ut dolorum nisi. Ullam incidunt a, at iste dolore perspiciatis dignissimos error quo nostrum quod illum, impedit enim odit illo pariatur excepturi. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laudantium, soluta vero, perspiciatis dolor, voluptates doloremque hic ipsum tenetur tempore maiores ipsa aliquam maxime veniam? Itaque nemo harum maxime. Sit, deserunt.',
  },
  {
    title: 'Contrary to popular belief, Lorem Ipsum is not simply random text ?',
    content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero tempore voluptatum harum ipsam temporibus non praesentium quo architecto, impedit velit. Aliquam tenetur incidunt maiores natus vitae. Laudantium officia ut dolorum nisi. Ullam incidunt a, at iste dolore perspiciatis dignissimos error quo nostrum quod illum, impedit enim odit illo pariatur excepturi. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laudantium, soluta vero, perspiciatis dolor, voluptates doloremque hic ipsum tenetur tempore maiores ipsa aliquam maxime veniam? Itaque nemo harum maxime. Sit, deserunt.',
  },
  {
    title: 'There are many variations of passages of Lorem Ipsum available ?',
    content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero tempore voluptatum harum ipsam temporibus non praesentium quo architecto, impedit velit. Aliquam tenetur incidunt maiores natus vitae. Laudantium officia ut dolorum nisi. Ullam incidunt a, at iste dolore perspiciatis dignissimos error quo nostrum quod illum, impedit enim odit illo pariatur excepturi. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laudantium, soluta vero, perspiciatis dolor, voluptates doloremque hic ipsum tenetur tempore maiores ipsa aliquam maxime veniam? Itaque nemo harum maxime. Sit, deserunt.',
  },
];



function Help() {

  const { Search } = Input;

  const [searchQuery, setSearchQuery] = useState('');

  const onSearch = (value, _e, info) => {
    setSearchQuery(value);
    console.log(info?.source, value);
  };


  return (
    <div>
      <Header />
      <div className="help">
        <div className="help-banner">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="heade">
                  <h2 className='mb-4'>How Can We Help You ?</h2>

                  <Space direction="vertical">
                    <Search placeholder="search text" allowClear onSearch={onSearch} className='searchbarCommponents' />
                  </Space>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="help-content">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="content">
                  <h3 className='py-lg-5 p-4'>FAQ's</h3>

                  <ol>

                    {sections
                      .filter(section => section.title.toLowerCase().includes(searchQuery.toLowerCase()))
                      .map((section, index) => (
                        <li className="section pb-3" key={index}>
                          <h5>{section.title}</h5>
                          <p>{section.content}</p>
                        </li>
                      ))}

                  </ol>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Help
