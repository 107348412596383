import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React from 'react';
import Home from './pages/Home'
import Login from './pages/Login';
import Reviews from './pages/Reviews';
import Analytics from './pages/Analytics';
import About from './pages/About';
import Help from './pages/Help';
import Contact from './pages/Contact';
import Ragister from './pages/Ragister';
import ForgotPassword from './pages/ForgotPassword';
import Notification from './pages/Notification';
import Account from './pages/Account';
import ChangePassword from './pages/ChangePassword';
import { useSelector } from 'react-redux';

function App() {

  const isLoggedIn = useSelector((state) => state.auth.isAuthenticate);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>

          {isLoggedIn ? (
            <>
              <Route path="/" element={<Home />} />
              <Route path="/Reviews" element={<Reviews />} />
              <Route path="/Analytics" element={<Analytics />} />
              <Route path="/About" element={<About />} />
              <Route path="/Help" element={<Help />} />
              <Route path="/Contact" element={<Contact />} />
              <Route path="/Notification" element={<Notification />} />
              <Route path="/Account" element={<Account />} />
              <Route path="/ChangePassword" element={<ChangePassword />} />
              <Route path="/*" element={<Navigate to="/" />} />
            </>
          ) : (
            <>
              <Route path="/Login" element={<Login />} />
              <Route path="/Ragister" element={<Ragister />} />
              <Route path='/ForgotPassword' element={<ForgotPassword />} />
              <Route path="/*" element={<Navigate to="/Login" />} />
            </>
          )}


        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
