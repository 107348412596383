import React, { useState } from 'react'
import Header from './section/Header'
import { useNavigate } from 'react-router-dom'


const ChangePassword = () => {

    const navigate = useNavigate();

    const [data, setData] = useState({
        newPassword: '',
        confirmPassword: ''
    });

    const [error, setError] = useState({
        newPassword: '',
        confirmPassword: ''
    });

    const validForm = () => {

        let isValid = true;
        const newError = { ...error };

        if (data.newPassword.trim() === '') {
            newError.newPassword = 'Required'
            isValid = false
        } else if (data.newPassword.length < 8) {
            newError.newPassword = 'Minimum 8 Charactor'
            isValid = false
        } else {
            newError.newPassword = ''
        }

        if (data.newPassword !== data.confirmPassword) {
            newError.confirmPassword = 'Password Not Match'
            isValid = false
        } else {
            newError.confirmPassword = ''
        }

        setError(newError)
        return isValid;
    }

    const ChangePassowrdSuccess = (e) => {
        e.preventDefault();

        const isValid = validForm();

        if (isValid) {

            console.log('Password Change:', data);

            alert('password Change Successfully')

            navigate('/');

        } else {

            alert('Enter Your Details')

        }
    }

    return (
        <div>
            <Header />
            <div className="changepassword-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">

                            <div>
                                <h2 className='mb-4'>Change Password</h2>

                                <form onSubmit={ChangePassowrdSuccess} method="post">

                                    <div className="form-group form-box">
                                        <label>New Password:</label>
                                        <input
                                            type="password"
                                            className='form-control'
                                            name='newpassword'
                                            value={data.newPassword}
                                            onChange={(e) => { setData({...data, newPassword: e.target.value}) }}
                                        />
                                        <p className='errormsg'>{error.newPassword}</p>
                                    </div>

                                    <div className="form-group form-box">
                                        <label>Confirm Password:</label>
                                        <input
                                            type="password"
                                            className='form-control'
                                            name='confirmPassword'
                                            value={data.confirmPassword}
                                            onChange={(e) => { setData({...data, confirmPassword: e.target.value}) }}
                                        />
                                        <p className='errormsg'>{error.confirmPassword}</p>

                                    </div>

                                    <div className="form-group">
                                        <button type='submit' className='btn btn-primary'>Change Password</button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword
