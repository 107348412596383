import React from 'react'
import Header from './section/Header'
import Chart1 from './section/Chart1'
import Chart2 from './section/Chart2'
import Chart3 from './section/Chart3'

function Analytics() {
  return (
    <div>
      <Header />
      <div className="container">
        <div className="row py-4">
          <div className="col-6">
            <h3>Analytics</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 pb-5 pb-lg-0">
            <Chart1 />
          </div>
          <div className="col-lg-4  pb-3 pb-lg-0">
            <Chart2 />
          </div>
        </div>
        <div className="row py-5">
          <div className="col">
            <Chart3 />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Analytics
